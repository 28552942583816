import React from "react";

import Layout from "../components/layout";
import BlogList from "../components/blog-list/blog-list";
import SEO from "../components/seo";

const BlogPage = () => (
  <Layout>
    <SEO title="Blog" />
    <BlogList />
  </Layout>
);

export default BlogPage;
