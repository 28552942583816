import React from "react"
import { graphql, StaticQuery } from 'gatsby'

import './blog-list.scss'

const BlogList = ({ data, count }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <div className="blog-list">
      {posts &&
        posts.map(({ node: post }) => (
          <article className="post-teaser" key={ post.id }>
            <h3 className="post-teaser__title">{ post.frontmatter.title }</h3>
            <h6 className="post-teaser__date">{ post.frontmatter.date }</h6>
            <p className="post-teaser__excerpt">{ post.excerpt }</p>
            <a href={ `/blog/${post.frontmatter.path}` } className="post-teaser__read-more">Read More</a>
          </article>
        ))
      }
    </div>
  );
}


export default () => (
  <StaticQuery 
    query={graphql`
      query BlogListQuery {
        allMarkdownRemark(
          sort: {order: DESC, fields: frontmatter___date},
          filter: {frontmatter: {content_type: {eq: "post"}}}
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              frontmatter {
                title
                date(formatString: "DD MMMM YYYY")
                path
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogList data={data} count={count} />}
  />
)
